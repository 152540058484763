// TODO: refactor API
import { KBANK_GATEWAY_API } from 'constants/APIUri';
import { GET } from './Clients';

export const getQRChargeInfo = async ({ ctx, chargeId }) => GET({ url: KBANK_GATEWAY_API.CHECK_QR_CHARGE, params: { chargeId }, ctx });

export const createCardCharge = async ({ ctx, token, orderId }) => GET({ url: KBANK_GATEWAY_API.CREATE_CARD_CHARGE, params: { token, orderId }, ctx });

export const getCardChargeInfo = async ({ ctx, chargeId }) => GET({ url: KBANK_GATEWAY_API.CHECK_CARD_CHARGE, params: { chargeId }, ctx, isBasic: true });

export default { getQRChargeInfo, getCardChargeInfo };
