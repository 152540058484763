import { KbankGatewayClient } from "clients";

export const getQRChargeInfo = async ({ chargeId = '' }) => {
    const result = await KbankGatewayClient.getQRChargeInfo({ chargeId });

    return result;
};

export const getCardChargeInfo = async ({ ctx, chargeId = '' }) => {
    const result = await KbankGatewayClient.getCardChargeInfo({ ctx, chargeId });

    return result;
};

export default {
    getQRChargeInfo,
    getCardChargeInfo
};